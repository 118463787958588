import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { LayoutMDX } from '@talves/gatsby-theme-site-layout';
import { Box, useThemeUI } from '../provider';
export const data = {
  title: "Developing, Consulting and Open Source",
  date: '2018-04-01T00:00:00-07:00',
  update: '2018-04-01T00:00:00-07:00',
  CircleImage: ({
    src = "",
    ...props
  }) => {
    const {
      theme
    } = useThemeUI();
    return <Box as="img" src={src} sx={{
      borderRadius: '50%',
      border: `5px solid ${theme.colors.accent}35`
    }} {...props} mdxType="Box" />;
  },
  theme: () => {
    const {
      theme
    } = useThemeUI();
    return theme;
  }
};
export const _frontmatter = {
  "title": "About Work",
  "description": "About my Consulting, Developing and Open Source work",
  "image": "/images/social/about.png"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  data,
  _frontmatter
};

const MDXLayout = props => {
  const {
    theme
  } = useThemeUI();
  return <LayoutMDX {...props} backgroundStyles={{
    background: 'url(/images/top-waves.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center' // backgroundSize: '100%',

  }} />;
}; // named exports come in as props, so props.data


export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Box sx={{
      ml: '20%',
      mr: 'auto',
      mb: [20, 30, 60],
      width: 120
    }} mdxType="Box">
  <data.CircleImage src="/images/3alves_logo_120.png" alt="3alves family logo" />
    </Box>
    <h2>{`This is where I talk about myself. `}<span role="img" aria-label="smile">{`😁`}</span></h2>
    <p>{`Just a little bit, I promise. The rest will be about what I like to work on lately.`}</p>
    <p>{`I've been a full stack developer for over 15 years, and developer over 25 years.`}</p>
    <h2>{`What I'm up to`}</h2>
    <p>{`I'm always looking for projects to work on and in the last year plus have been changing over from full enterprise development work to concentrating more on newer technologies that allow for more diversified customer types with internet facing development. Available for projects or consulting.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Javascript`}</strong>{` developer (Web Apps)`}</li>
      <li parentName="ul"><strong parentName="li">{`JAMStack driven websites`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Static site generators:`}</strong><br />{`Hugo`}<br />{`Gatsby`}<br />{`React-Static`}<br />{`Among others...`}</li>
      <li parentName="ul"><strong parentName="li">{`React Applications`}</strong>{` and React built sites`}</li>
      <li parentName="ul"><strong parentName="li">{`NoSQL API driven databases`}</strong><br />{`Firebase/Firestore`}</li>
      <li parentName="ul"><strong parentName="li">{`Git`}</strong>{` using Github, Gitlab, Bitbucket for source control`}</li>
      <li parentName="ul"><strong parentName="li">{`Netlify`}</strong>{` Deployment Strategies (site hosting and more)`}</li>
      <li parentName="ul"><strong parentName="li">{`Netlify CMS`}</strong>{` open source contributor/maintainer`}<br />{`(content management for Git workflow)`}</li>
      <li parentName="ul"><strong parentName="li">{`NodeJS`}</strong>{` driven development workflow`}</li>
    </ul>
    <p><strong parentName="p">{`This site is written using most of the technologies above.`}</strong></p>
    <p>{`You can find me writing about some of the above on my blog here or can follow some of my projects that are open source on `}<a parentName="p" {...{
        "href": "https://github.com/talves"
      }}>{`GitHub`}</a>{`. I contribute heavily to the NetlifyCMS open source project whenever I have free time.`}</p>
    <h2>{`Other Technologies I know and have worked on`}</h2>
    <ul>
      <li parentName="ul">{`Programming in C#, PHP, VisualBasic, and more`}</li>
      <li parentName="ul">{`SQL databases: SQL Server, MySQL`}</li>
      <li parentName="ul">{`Windows, Linux (limited now)`}</li>
      <li parentName="ul">{`Crazy number of development tools and IDE's`}</li>
      <li parentName="ul">{`Even worked on main frame systems, but shhh, don't tell anyone`}</li>
    </ul>
    <p>{`If you got way down here on the page, I'm sorry, I don't have a prize for you, but thanks for taking the time. Might want to read just a little more?`}</p>
    <h2>{`What people don't typically know about me`}</h2>
    <ul>
      <li parentName="ul">{`I hold a valid California contractors license. The home building kind! 🏠`}</li>
      <li parentName="ul">{`Have built a ton of homes from the ground up`}</li>
      <li parentName="ul">{`Used to buy and flip foreclosed homes`}</li>
      <li parentName="ul">{`Have an oil painting hanging in my home I painted`}</li>
      <li parentName="ul">{`Have a teenage son who is an ocean lifeguard who also took all pics on this site`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      